var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"users-create-modal"},[_c('ModalContainer',{attrs:{"title":_vm.$t('vehicles.vehicles_brands'),"identifier":"vehicles-brand-index-modal","closable":true}},[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table vgt-custom vgt-white","columns":_vm.columns,"rows":_vm.brands,"isLoading":_vm.is_loading_brands,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: false,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('vehicles.brands')}),
            },"sort-options":{
              enabled: true,
              multipleColumns: true,
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_brands=$event},"update:is-loading":function($event){_vm.is_loading_brands=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'attributes.brand')?_c('div',[_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(props.row.relationships.brand.data.attributes.name))])]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasAnyPermission(['read brands', 'update brands']))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleEdit(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1):_vm._e(),(_vm.$store.getters.hasPermission('destroy brands'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleDelete(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticClass:"buttons"},[_c('Button',{attrs:{"className":`--primary wider`,"onclick":()=>_vm.close(true)}},[_vm._v(_vm._s(_vm.$t('save')))]),(_vm.$store.getters.hasPermission('store brands'))?_c('Button',{attrs:{"className":`--primary --outline wider`,"onclick":_vm.addBrand}},[_vm._v(" "+_vm._s(_vm.$t('vehicles.add_brand'))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }