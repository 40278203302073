<template>
    <div class="bookings-create-modal mb-36">
        <ModalContainer :title="$t('bookings.add_booking')" identifier="bookings-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputDateTime v-model="$v.booking.from.$model" identifier="pick_up_date" :label="$t('bookings.pick_up_date')"
                                       :placeholder="$t('bookings.pick_up_date')" :disabled="is_saving" :minute-interval="60"
                                       :has-error="$v.booking.from.$error || is_time_equal || is_date_different" :min-date="todayDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.from.required">
                                {{$t('validation.x_is_required',{x: $t('bookings.pick_up_date')})}}
                            </p>
                            <p v-else-if="is_time_equal">
                                {{$t('bookings.pick_up_time_cant_be_same')}}
                            </p>
                            <p v-else-if="is_date_different">
                                {{$t('bookings.pick_up_date_cant_be_different')}}
                            </p>
                        </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="$v.booking.to.$model" identifier="drop_off_date" :label="$t('bookings.drop_off_date')"
                                       :placeholder="$t('bookings.drop_off_date')" :disabled="is_saving" :minute-interval="60"
                                       :has-error="$v.booking.to.$error" :min-date="minReturnDate" :max-date="maxReturnDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.to.required">
                                {{$t('validation.x_is_required',{x: $t('bookings.drop_off_date')})}}
                            </p>
                        </template>
                    </FormInputDateTime>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputText v-model="$v.booking.pick_up_location.$model" identifier="pick_up_location"
                                   :label="$t('bookings.pick_up_location')"
                                   :placeholder="$t('bookings.pick_up_location')" :disabled="is_saving"
                                   :has-error="$v.booking.pick_up_location.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.pick_up_location.required">
                                {{ $t('validation.x_are_required', {x: $t('bookings.pick_up_location')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.booking.drop_off_location.$model" identifier="drop_off_location"
                                   :label="$t('bookings.drop_off_location')"
                                   :placeholder="$t('bookings.drop_off_location')" :disabled="is_saving"
                                   :has-error="$v.booking.drop_off_location.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.drop_off_location.required">
                                {{ $t('validation.x_are_required', {x: $t('bookings.drop_off_location')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputSelect v-model="$v.booking.license_plate.$model" identifier="license_plate"
                                 :label="$t('bookings.license_plate')" :options="vehicleOptions"
                                 :placeholder="$t('bookings.license_plate')" :disabled="is_saving"
                                 :has-error="$v.booking.license_plate.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`">
                    <template v-slot:errors>
                        <p v-if="!$v.booking.drop_off_location.required">
                            {{ $t('validation.x_are_required', {x: $t('bookings.license_plate')}) }}
                        </p>
                    </template>
                </FormInputSelect>
                <FormInputSelect v-model="$v.booking.user.$model" identifier="user"
                                 :label="$t('bookings.user')" :options="userOptions"
                                 :placeholder="$t('bookings.user')" :disabled="is_saving"
                                 :has-error="$v.booking.user.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.column}`">
                    <template v-slot:errors>
                        <p v-if="!$v.booking.user.required">
                            {{ $t('validation.x_are_required', {x: $t('bookings.user')}) }}
                        </p>
                    </template>
                </FormInputSelect>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputText v-model="$v.booking.obo.$model" identifier="obo"
                                   :label="$t('bookings.obo')"
                                   :placeholder="$t('bookings.obo')" :disabled="is_saving"
                                   :has-error="$v.booking.obo.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.obo.required">
                                {{ $t('validation.x_are_required', {x: $t('bookings.obo')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('bookings.book')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
export default {
    name: "BookingsCreateModal",
    components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            booking: {
                from: null,
                to: null,
                pick_up_location: null,
                drop_off_location: null,
                license_plate: null,
                user: null,
                obo: null,
            },
            is_saving: false,
            is_loading_vehicles: false,
            is_time_equal: false,
            is_date_different: false,
            is_loading_users: false,
            userOptions: [],
            locationOptions: [],
            vehicleOptions: [],
        }
    },
    validations: {
        booking: {
            from: {required},
            to: {required},
            pick_up_location: {required},
            drop_off_location: {required},
            license_plate: {required},
            user: {required},
            obo: {},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('bookings-create-modal', status);
        },
        save() {
            this.is_time_equal = false;
            this.is_date_different = false;
            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving) return;
            this.is_saving = true;

            const payload = Object.assign({}, this.$v.booking.$model);
            payload.vehicle_id = payload.license_plate.id
            payload.user_id = payload.user.id
            payload.status = 'pending'

            delete payload.license_plate;

            if(payload.from === payload.to){
                this.is_time_equal = true;
                this.is_saving = false;
                return;
            }

            if(payload.from.substring(0,10) !== payload.to.substring(0,10)){
                this.is_date_different = true;
                this.is_saving = false;
                return;
            }

            this.$axios.post(`vehicles/${payload.vehicle_id}/book`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('bookings.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;
                if (e.response.data.message === 'Slot is not available') {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$t('bookings.slot_not_available'),
                        type: 'error',
                    });
                }

                else {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('bookings.error_create')),
                        type: 'error',
                    });
                }
            });
        },

        retrieveVehiclesOptions() {
            this.is_loading_vehicles = false;
            this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicleOptions = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveUserOptions() {
            this.is_loading_users = true;
            this.$axios.get('users/list')
                .then(({data}) => {
                    this.userOptions = data.data;
                    this.is_loading_users = false;
                })
                .catch(e => {
                    this.is_loading_users = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    mounted() {
        this.retrieveVehiclesOptions();
        this.retrieveUserOptions();

    }
}
</script>


